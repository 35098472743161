<template>
  <div class="listBox">
    <div
      class="listItem"
      v-for="(item, index) in list"
      :index="index"
      :key="item.id"
    >
      <div class="right">
        <div class="title">
          <div class="titleItem">
            {{ item.title ? item.title : item.name }}
          </div>
          <div><span>{{i18nData.time}}: </span>{{ item.createtime | dateFormat }}</div>
        </div>
        <div class="commitBox">
          <span v-html="item.content"></span>
        </div>
        <div class="date">
          <!-- <div class="other" @click="toPage(item.id)">
            <span>to Submited</span><span>></span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "./../common/Format.js";
export default {
  name: "subList",
  props: {
    archivesList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    list() {
      if (this.archivesList.length > 0) {
        return this.archivesList;
      }
      return this.defaultList;
    }
  },
  data() {
    return {
      defaultList: []
    };
  },
  methods: {
    toPage(index) {
      this.$router.push({
        path: "/detail",
        query: {
          id: index
        }
      });
    },
    async del(index, id) {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          id: id
        };
        let res = await this.$api.https.delCollection(params);
        console.log(res);

        this.$message({
          message: res.msg
        });
        if (res.code == 1) {
          this.archivesList.splice(index, 1);
        }

        let { collectionList } = res.data;
        this.channel = collectionList.channel;
        this.total = collectionList.total;
        this.collectionList = [...collectionList.data];
      } catch (err) {
        console.log(err);
      }
    }
  },
  filters: {
    dateFormat
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$vue_color: #9b0000;
.listBox {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 10px;
  padding: 0 0;
  .listItem {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    .right {
      flex: 1;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .titleItem {
          font-size: 14px;
          font-weight: bold;
          color: $vue_color;
        }

        .date {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .commitBox {
        flex: 1;
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        box-sizing: border-box;
        background-color: #e7e7ef;
        border-radius: 5px;
        ::v-deep em {
          color: #409eff;
        }
      }
    }
  }
}
</style>
